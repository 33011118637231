import React from "react"
import { Link } from "gatsby"

// Components
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Dot from "../../components/dot"

// Styles
import "../../css/pages/work.css"

// Images
import modal from "../../images/sfy/modal.svg"


const ETHSurveyPage = () => (
  <Layout>
    <SEO title="MPX" />
    <section className="sfy-banner work-banner bg-black h-64 py-20 px-4 lg:px-0 text-white">
      <div className="container flex flex-col h-full justify-between">
        <div className="flex flex-col justify-around h-full">
					<h2 className="font-header">Side Project <Dot/></h2>
          <div>
            <h1 className="font-bold">Subtitles for YouTube</h1>
            <p className="text-white mt-5 text-shadow">A chrome extenstion to add or search subtitles on youtube videos</p>
          </div>
        </div>
      </div>
    </section>

    <div className="container pt-16 px-4 lg:px-0 flex flex-col sm:flex-row justify-between">
      <div className="font-serif flex justify-between sm:justify-start">
        <span className="font-bold font-header">duration :</span> &nbsp;&nbsp;<i>Jan 2018 - Feb 2018</i>
      </div>
      <div className="font-serif flex justify-between sm:justify-start mt-4 sm:mt-0">
        <span className="font-bold font-header">Role:</span> &nbsp;&nbsp;<i>Product Designer</i>
      </div>
    </div>

    <section className="container pt-16 px-4 lg:px-0">
      <h4 className="font-header mb-5">brief <Dot/></h4>
      <p>
        Subtitles for YouTube is a chrome extension which does exactly as the name suggests, adds subtitles for YouTube videos.
        Currently, it's been used by 40k+ users and the extension allows us to add any .SRT(subtitle file format)
        or search any subtitles to be added to the video from the opensource subtitle library projects such as Amara
        or OpenSubtitles. This was developed by a friend of mine and he requested me to help around with the interface designs
        and the implementation of it.
      </p>
    </section>
    <section className="container pt-16 md:pt-24 px-4 lg:px-0">
      <h4 className="font-header mb-5">initial ideations <Dot/></h4>
      <p>
				It was a first time for me to work on a chrome extension, be it designing or developing it. I consider myself as an average chrome extensions user. I had to dig deeper into what extent a chrome extension can actually alter the behavior of the web page experience.
      </p>
			<p>With the simple reading of docs, found out that a chrome extension can replace or change any aspects of the loaded DOM in a web page.</p>
			<p>I also categorized the usual extensions into the following</p>
			<ul>
				<li>Performing actions through the popup from browser's extension bar</li>
				<li>Altering existing web page content or elements</li>
				<li>Adding new elements along with the existing web page content or elements</li>
			</ul>
    </section>

    <section className="container lg text-center px-4 lg:px-0">
      <img src="https://res.cloudinary.com/ds24tivvl/image/upload/v1568736869/portfolio/idkeepxvrczentroa5pm.png" alt="types of extension" className="mb-5" />
      <p className="font-header text-xs">Various possible types of extensions</p>
    </section>

    <section className="container pt-24 px-4 lg:px-0">
			<h4 className="font-header mb-5">Designs <Dot/></h4>
      <p>There were three main actions of the extenstion which the user will interact on.</p>
      <ul>
        <li>Search Subtitles - Search any subtitles and apply them to the video</li>
        <li>Upload Subtitles - Use custom subtitles for the video</li>
        <li>Settings - Few user settings on default language, subtitle providers etc</li>
      </ul>
      <p>After getting to know the possibilities of a chrome extension, understanding what type of users would benefit such extensions - I decided to make the subtitles search and upload functionality inclusive with the existing YouTube UI instead of the popup UI which would be an obtrusive experience. My main goal was to not alter the existing video viewing experience of YouTube which is awesome.</p>
    </section>

    <section className="container lg text-center my-20 px-4 lg:px-0">
      <div className="mb-24">
        <img src="https://res.cloudinary.com/ds24tivvl/image/upload/v1568737545/portfolio/rlnq50b9pngioonppxdl.png" alt="extension" className="mb-5 shadow" />
        <p className="font-header text-xs">Addition of the subtitles option along with other YouTube options to keep it consistent</p>
      </div>
    </section>

		<section className="bg-black-gradient my-32 pt-12 md:pt-24 text-center">
      <div className="container lg text-center px-4 lg:px-0">
        <div className="flex justify-between flex-wrap">
					<img src="https://res.cloudinary.com/ds24tivvl/image/upload/v1568737858/portfolio/heejfmtwkqrxabkx6foh.png" alt="screenshot 1" className="md:pr-8 mt-16 md:mt-0 md:w-1/2 md:h-full" />
					<img src="https://res.cloudinary.com/ds24tivvl/image/upload/v1568737869/portfolio/dzz33cgw6cuod2nmj3ra.png" alt="screenshot 2" className="md:pl-8 mt-16 md:mt-0 md:w-1/2 md:h-full" />
				</div>
				<div className="flex justify-between flex-wrap mt-0 md:mt-16">
					<img src="https://res.cloudinary.com/ds24tivvl/image/upload/v1568737878/portfolio/poxiltz3njocfkjjxdwu.png" alt="screenshot 2" className="md:pr-8 mt-16 md:mt-0 md:w-1/2 md:h-full" />
					<img src="https://res.cloudinary.com/ds24tivvl/image/upload/v1568737890/portfolio/beghu3u0knjts8t74don.png" alt="screenshot 3" className="md:pl-8 mt-16 md:mt-0 md:w-1/2 md:h-full" />
				</div>
				<p className="font-header text-xs mt-12">Appstore screenshots showing the functionality</p>
			</div>
		</section>
    <section className="container lg text-center my-20 px-4 lg:px-0">
      <div className="mb-24">
        <img src='https://res.cloudinary.com/ds24tivvl/image/upload/v1569336607/portfolio/marketing_website.png' alt="marketing website" className="mb-5 shadow" />
        <p className="font-header text-xs">Marketing website for the extension</p>
      </div>
    </section>

		<section className="container pt-16 md:pt-24 flex flex-col px-4 lg:px-0">
      <h4 className="font-header mb-5">Challenges <Dot/></h4>
      <p>
				So far, I liked the way Subtitles options was introduced without any obstruction to the users. But I found two main challenges which need to be addressed:
      </p>
			<ul>
				<li>
				There was a possibility where users might actually miss out on the sweet new subtitle tab below the video since there was no prior education for the user unless they saw the screenshots posted on the extension listing.
				</li>
				<li>What if YouTube changes their UI?</li>
			</ul>
			<div className="flex justify-center my-16">
				<img src={modal} alt="onboarding modal" />
			</div>
			<p className="mt-5">Decided to add a nifty on-boarding modal for the first time when the extension loads to handle education to users regarding the new extension which was installed. This creates visibility and a way of interaction to the users instead of just getting installed in the background. Regarding YouTube changing their UI, I believe the only way to handle was to update the extension to support their new UI when it changes.</p>
    </section>


    <section className="container pt-16 md:pt-24 flex flex-col px-4 lg:px-0">
      <h4 className="font-header mb-5">Key Takeaways <Dot/></h4>
      <p>
				This was a fun project to work on. I learned a lot on building extensions, got my hands dirty with the way of modifying users experiences on websites which I believe is a very powerful tool.
      </p>
    </section>

    <section className="container mt-16 mb-32 flex justify-between px-4 lg:px-0">
      <Link to="/work/tikkle" className="line">
        previous project
      </Link>
      <Link to="/work/mpx" className="line">
        next project
      </Link>
    </section>

  </Layout>
)

export default ETHSurveyPage
